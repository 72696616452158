<template>
  <button :disabled="disabled" :class="['CheckMark', checked ? 'is-checked' : undefined]">
    <i v-if="checked" class="CheckMark__icon far fa-check"></i>
  </button>
</template>

<script>
import IconButton from '@components/Buttons/Icon';
export default {
  name: 'CheckMark',
  components: {
    IconButton,
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.CheckMark {
  color: $color-prim-black-light;
  border: 2px solid $color-prim-black-light;
  font-size: 14px;
  opacity: 1;
  width: 22px;
  height: 22px;
  overflow: hidden;
  padding: 0;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: none;
  outline: none;
}
.CheckMark__icon {
}
</style>
