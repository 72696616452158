var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'Input',
    _vm.size === _vm.InputSize.SMALL && 'Input--small',
    _vm.inputValidated === false && _vm.initiated && 'Input--not-validated' ],attrs:{"tabIndex":0}},[_c('div',{class:['InputWrapper', _vm.inputValue === 'number' && 'InputWrapper--number']},[_c('input',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSelected),expression:"isSelected"}],ref:"input",class:[_vm.unit !== '' ? 'has-suffix' : undefined],attrs:{"placeholder":_vm.placeholder,"type":_vm.inputValue,"max":_vm.max,"min":_vm.min},domProps:{"value":_vm.value},on:{"input":function (e) {
          _vm.initiateInput();

          // Make sure value isn't outside boundaries
          var value = e.target.value;

          if (_vm.type === _vm.InputType.NUMBER) {
            value = parseFloat(value);

            if (_vm.max !== '' && value > _vm.max) {
              value = _vm.max;
            } else if (_vm.min !== '' && value < _vm.min) {
              value = _vm.min;
            }
          }

          // Send action with value
          _vm.action(value);
        },"blur":function (e) {
          // Toggle selected
          _vm.toggleSelected();

          // If blurAction is undefined, run action istead - if it's defined
          if (_vm.blurAction === undefined && _vm.action !== undefined) {
            _vm.action(_vm.value);
          } else if (_vm.blurAction !== undefined) {
            // If both blurAction and action are undefined, then we shouldn't run this
            // otherwise, default to bluraction
            _vm.blurAction(_vm.value);
          }
        }}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isSelected),expression:"!isSelected"}],class:['Input__fakeInput', _vm.unit !== '' && 'Input__FakeInput--hasSuffix'],style:('width: ' + _vm.inputWidth + 'px'),on:{"mousedown":_vm.toggleSelected}},[(_vm.value === '')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.placeholder || '&nbsp;')}}):_vm._e(),_vm._v(" "+_vm._s(_vm.helpers.manipulation.getInputFormatByType(_vm.value, _vm.type, this))+" ")]),(_vm.unit)?_c('div',{staticClass:"InputWrapper__unit"},[_vm._v(_vm._s(_vm.unit))]):_vm._e()]),(!_vm.inputValidated && _vm.initiated)?_c('div',{class:['ErrorWrapper', _vm.tight && 'ErrorWrapper--tight']},[_vm._v(" "+_vm._s(_vm.validationErrorMessage)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }