<template>
  <div class="RmaOrder">
    <div class="RmaOrder__headline">
      <span class="RmaOrder__title"
        >Faktura: <span>{{ order.invoice_number }}</span></span
      ><span class="RmaOrder__date">Dato: <Time :datetime="order.created" format="LL"/></span>
    </div>

    <ul class="RmaOrder__lines">
      <li v-for="(line, index) in order.lines" :key="index" class="RmaOrder__item">
        <RmaOrderLine ref="lines" :order-line="line" :invoice-number="order.invoice_number" />
      </li>
    </ul>
  </div>
</template>

<script>
import RmaOrderLine from './components/RmaOrderLine';
import Time from '@components/Time';

export default {
  name: 'RmaOrderList',
  components: {
    RmaOrderLine,
    Time,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    addLines: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {},
  methods: {
    remove() {
      if (this.$refs.lines !== undefined) {
        this.$refs.lines.forEach(orderLine => orderLine.remove());
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.RmaOrder {
  .RmaOrder__headline {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8em;
    //padding-bottom: 0.3em;
    //border-bottom: 1px solid #eeeeee;
    align-items: center;
  }
  .RmaOrder__title,
  .RmaOrder__date {
    //color: $color-prim-black-light;
    font-weight: bold;
    margin-right: 0.5em;

    span,
    time {
      font-weight: normal;
    }
  }
  .RmaOrder__item {
    margin-bottom: 20px;
  }
  .RmaOrder__lines {
    padding: 0 10px;
  }
}
</style>
