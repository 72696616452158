<template>
  <div class="ReturnContainerSelect">
    <div v-if="label" class="ReturnContainerSelect__label">{{ label }}:</div>
    <Select
      v-if="containers.length"
      :default-choice="placeholder"
      :items="items"
      :action="value => $emit('change', value)"
    />
  </div>
</template>

<script>
import Select from '@components/Select';
import SpecialDeliveryContainer from '@scenes/SelfserviceScene/containers/SpecialDeliveryContainer';

export default {
  name: 'ReturnContainerSelect',
  components: { Select },
  mixins: [SpecialDeliveryContainer],
  props: {
    selected: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Vælg kasse',
    },
  },
  computed: {
    items() {
      if (!this.containers.length) {
        return [];
      }
      return [
        ...this.containers.map(container => {
          return {
            value: container.container_id,
            label: 'Kasse: ' + container.container_id,
            isRefined: this.selected === container.container_id.toString(),
          };
        }),
        {
          value: 'other',
          label: 'Andet',
          isRefined: this.selected === 'other',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.ReturnContainerSelect {
  display: flex;
  align-items: center;

  .ReturnContainerSelect__label {
    margin-right: 0.6em;
  }
}
</style>
