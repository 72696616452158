<template>
  <Validator v-slot="{ valid }" :error-visible.sync="showingError">
    <div
      :class="[
        'RmaOrderLookup',
        hideHeader && 'RmaOrderLookup--borderless',
        showingError && 'has-validation-error',
      ]"
    >
      <div>
        <div v-if="!hideHeader" class="RmaOrderLookup__header">
          <Header
            :query="query"
            :status="headerStatus.message"
            :status-type="headerStatus.type"
            :progress="status === RmaInvoicesContainerStates.SEARCHING"
            @toogle="$emit('toogle')"
            @remove="onRemove"
          />
        </div>
        <Collapse>
          <div v-show="!collapsed && (status === RmaInvoicesContainerStates.SUCCESS || hideHeader)">
            <ProgressOverlay
              message="Indlæser"
              class="RmaOrderLookup__progressOverlay"
              :progress="status === RmaInvoicesContainerStates.SEARCHING && hideHeader"
            >
              <div
                v-if="status === RmaInvoicesContainerStates.SUCCESS"
                class="RmaOrderLookup__content"
              >
                <ul v-if="invoices.length > 0" class="RmaOrderLookup__invoices">
                  <li
                    v-for="(order, index) in invoices"
                    :key="order.id"
                    class="RmaOrderLookup__invoice"
                  >
                    <RmaOrderList ref="orderLists" :order="order" />
                  </li>
                </ul>
                <Reference
                  v-else-if="!fulfilled"
                  ref="reference"
                  class="RmaOrderLookup__Reference"
                  :query="query"
                  @remove="onRemove"
                ></Reference>
              </div>
            </ProgressOverlay>
          </div>
        </Collapse>
      </div>
    </div>
  </Validator>
</template>

<script>
import RmaInvoicesContainer from '@scenes/SelfserviceScene/containers/RmaInvoicesContainer';
import { states as RmaInvoicesContainerStates } from '@scenes/SelfserviceScene/containers/RmaInvoicesContainer/types';
import ProgressOverlay from '@components/ProgressOverlay';
import RmaOrderList from '../../components/RmaOrderList';
import Reference from './components/Reference';
import Header from './components/Header';
import Collapse from '@transitions/Collapse';
import Validator from '@containers/Validator';

export default {
  name: 'RmaOrderLookup',
  components: {
    ProgressOverlay,
    RmaOrderList,
    Header,
    Collapse,
    Reference,
    Validator,
  },
  mixins: [RmaInvoicesContainer],
  props: {
    lookup: {
      type: Object,
      required: true,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    executeOnMounted: {
      type: Boolean,
      default: true,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showingError: false,
    };
  },
  computed: {
    lookupList() {
      return Object.entries(this.lookup).map(([key, value]) => {
        return {
          type: key,
          label: key !== 'query' ? this.$t(`lookup.labels.${key}`) : '',
          value,
        };
      });
    },
    fulfilled() {
      return false;
    },
    itemCount() {
      return 0;
    },
    query() {
      return this.lookupList && this.lookupList[0] && this.lookupList[0].value;
    },
    headerStatus() {
      if (this.status === RmaInvoicesContainerStates.SUCCESS) {
        if (this.fulfilled) {
          return { message: Number(this.itemCount) + ' vare er valgt', type: 'success' };
        } else if (this.invoices.length > 0) {
          return { message: 'Fandt ' + this.invoices.length + ' resultater' };
        } else {
          return { message: 'Fandt ingen resultater', type: 'error' };
        }
      } else {
        return { message: 'Indlæser' };
      }
      return '';
    },
  },
  created() {
    this.RmaInvoicesContainerStates = RmaInvoicesContainerStates;
  },
  mounted() {
    if (this.lookup) {
      this.search(this.lookup);
    }
  },
  methods: {
    onRemove() {
      if (this.$refs.orderLists !== undefined) {
        this.$refs.orderLists.forEach(orderList => orderList.remove());
      }
      if (this.$refs.reference) {
        this.$refs.reference.remove();
      }
      this.$emit('remove');
    },
  },
  inject: ['rmaContainer'],
};
</script>

<style lang="scss" scoped>
.RmaOrderLookup {
  border: 1px solid $color-prim-grey;
  border-radius: 2px;
  &.RmaOrderLookup--borderless {
    border: none;
  }

  &.has-validation-error {
    border: 1px solid $color-extra-error;
  }
  .RmaOrderLookup__progressOverlay {
    min-height: 80px;
  }
  .RmaOrderLookup__content {
    padding: 20px;
  }
  .RmaOrderLookup__invoices > .RmaOrderLookup__invoice {
    margin: 0;
    margin-bottom: 0;
    //padding-bottom: 10px;
    padding-top: 10px;
    //border-bottom: 1px solid $color-prim-grey-light;
  }
}
</style>
