<template>
  <div class="Reference">
    <div v-if="quantity === 0">
      <h2 class="Reference__headline">Fandt ingen resultat med "{{ query }}"</h2>
      <p>Vi fandt ingen faktura med søgningen.</p>
      <p>Har du tastet forkert? eller vil du tilføje referencen alligvel?</p>
      <div class="Reference__options">
        <Button :size="buttonSizes.SMALL" @click="updateLine({ quantity: 1 })">Tilføj</Button>
        <Button type="light" :size="buttonSizes.SMALL" @click="$emit('remove')">Fjern</Button>
      </div>
    </div>

    <div v-else class="Reference__form">
      <div class="Reference__description">
        <div class="Reference__descriptionInput">
          <InputComponent
            ref="itemNameInput"
            class="Input Input--tight"
            :value="itemName"
            placeholder="Beskriv varen her"
            :required="true"
            :validation-action="onInputValidation"
            :action="value => updateLine({ item_name: value })"
          />
        </div>
      </div>

      <div class="Reference__returnMethod">
        <ReturnContainerSelect
          :selected="line.return_method_id"
          @change="updateLine({ return_method_id: $event })"
        />
      </div>
      <div class="Reference__count">
        <div class="Reference__countLabel">Antal:</div>
        <div class="Reference__countInput">
          <div class="Input Input--tight">
            <input
              type="number"
              :value="quantity"
              @change="updateLine({ quantity: Number($event.target.value) })"
              @keyup="updateLine({ quantity: Number($event.target.value) })"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, sizes } from '@components/Buttons';
import ReturnContainerSelect from '../../../ReturnContainerSelect';
import InputComponent from '@components/Input';
import Fade from '@transitions/';
export default {
  name: 'Reference',
  components: {
    Button,
    InputComponent,
    ReturnContainerSelect,
  },
  props: {
    query: {
      type: String,
      default: '',
    },
    accepted: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      inputValid: false,
    };
  },
  computed: {
    line() {
      return (
        this.rmaContainer.getDraftLine({ item_reference: this.query }) || {
          item_reference: this.query,
          quantity: 0,
        }
      );
    },
    quantity() {
      return this.line ? this.line.quantity : 0;
    },
    itemName() {
      return this.line ? this.line.item_name : '';
    },
    fulfilled() {
      return this.line.quantity > 0 && this.line.item_name;
    },
  },
  watch: {
    fulfilled(to, from) {
      if (to !== from) {
        this.validator.updateItem(this._uid, to);
      }
    },
  },
  beforeDestroy() {
    this.validator.removeItem(this._uid);
  },
  created() {
    this.buttonSizes = sizes;
    this.validator.addItem(this._uid, false, this.showError);
  },
  methods: {
    updateLine(upsertData) {
      this.rmaContainer.upsertDraftLine({ ...this.line, ...upsertData });
    },
    remove() {
      this.rmaContainer.removeDraftLine(this.line);
    },
    onInputValidation(valid) {
      this.inputValid = valid;
    },
    showError() {
      if (this.$refs.itemNameInput) {
        // validate input?
      }
    },
  },
  inject: ['rmaContainer', 'validator'],
};
</script>

<style lang="scss" scoped>
.Reference {
  text-align: center;

  .Reference__headline {
    font-size: 16px;
  }
  .Reference__options > * {
    margin: 5px;
  }

  .Reference__form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
  }
  .Reference__description {
    flex: 1;
  }

  .Reference__count {
    margin-left: 20px;
    display: flex;
    align-items: center;

    .Reference__countInput {
      max-width: 58px;
    }
    .Reference__countLabel {
      margin-right: 0.6em;
    }
  }
  .Reference__description {
    margin-right: 20px;
  }
  .Reference__descriptionInput {
    max-width: 400px;
  }
}
</style>
