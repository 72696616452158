import { getInvoiceLines, getInvoiceLinesByQuery } from '@services/thansen/rma';
import { states } from './types';

export default {
  name: 'RmaInvoicesContainer',
  props: {},
  computed: {},
  data() {
    return {
      invoices: [],
      status: '',
      refinement: '',
    };
  },
  methods: {
    getByQuery(query) {
      this.status = states.SEARCHING;
      getInvoiceLinesByQuery(query).then(response => {
        this.refinement = response.refinement;
        this.invoices = response.results;
        this.status = states.SUCCESS;
      });
    },

    getByInvoiceNumber(invoiceNumber) {
      this.status = states.SEARCHING;
      getInvoiceLines(invoiceNumber).then(this.onOrderResponse);
    },

    onOrderResponse(response) {
      this.invoices = Array.isArray(response) ? response : [response];

      this.status = states.SUCCESS;
    },
    onError(err) {
      console.log(err);
      this.status = states.ERROR;
    },
    search(searchObj) {
      switch (searchObj.type) {
        case 'itemNumber':
          this.getByItemNumber(searchObj.query);
          break;
        case 'invoiceNumber':
          this.getByInvoiceNumber(searchObj.query);
          break;
        case 'orderNumber':
          this.getByOrderNumber(searchObj.query);
          break;

        default:
          this.getByQuery(searchObj.query);
          break;
      }
    },
  },

  created() {
    /*
    if (this.itemNumber) {
      this.getByItemNumber(this.itemNumber);
    } else if (this.invoiceNumber) {
      this.getByInvoiceNumber(this.invoiceNumber);
    } else if (this.orderNumber) {
      this.getByOrderNumber(this.orderNumber);
    }
    */
  },

  render() {
    return this.$scopedSlots.default({
      invoices: this.invoices,
      status: this.status,
      search: this.search,
      getByItemNumber: this.getByItemNumber,
      getByInvoiceNumber: this.getByInvoiceNumber,
      getByOrderNumber: this.getByOrderNumber,
    });
  },
};
