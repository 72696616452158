<template>
  <div class="RmaLinks">
    <template v-for="(rma, index) in rmas">
      <span :key="rma.id" class="RmaLinks__link">
        <router-link :to="{ name: '/selfservice/returns/:id', params: { id: rma.id } }">
          {{ rma.id }}</router-link
        >
      </span>
      <template v-if="index < rmas.length - 1">, </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'RmaLinks',
  components: {},
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    rmas() {
      const rmas = [];
      let count = 0;
      this.items.forEach(item => {
        const exisiting = rmas.find(id => item.id);
        if (exisiting !== undefined) {
          exisiting.quantity += item.quantity;
        } else {
          count += 1;
          rmas.push({
            id: item.id,
            quantity: item.quantity,
          });
        }
      });

      return rmas;
    },
  },
};
</script>

<style lang="scss" scoped>
.RmaLinks {
  display: inline-block;
  .RmaLinks__link {
    white-space: nowrap;
  }
  .RmaLinks__link a {
    text-decoration: underline;
    color: inherit;
  }
}
</style>
