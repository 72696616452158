<template>
  <div :class="['OrderLine', disabled && 'OrderLine--disabled']">
    <div class="OrderLine__check">
      <CheckMark :disabled="disabled" :checked="quantity > 0" @click.native="toogleLine" />
    </div>

    <div class="OrderLine__info" @click="toogleLine">
      <StatusLabel
        v-if="quantityAddedToRma > 0"
        class="OrderLine__label"
        :light="true"
        :solid="true"
        :type="labelTypes.SUCCESS"
      >
        <template #icon>
          <i class="far fa-check"></i>
        </template>
        <template #default>
          {{ $tc('rmaReturns.addedToCurrentReturn', rmas.length, { n: quantityAddedToRma }) }}
        </template>
      </StatusLabel>
      <StatusLabel
        v-else-if="rmas.length > 0"
        class="OrderLine__label"
        :light="true"
        :solid="true"
        :type="labelTypes.WARNING"
      >
        <template #icon>
          <i class="far fa-times"></i>
        </template>
        <template #default>
          {{ $tc('rmaReturns.addedToOtherReturns', rmas.length, { n: quantityAddedToRmas }) }}:
          <RmaLinks v-if="rmas.length > 0" :items="rmas" />
        </template>
      </StatusLabel>

      <ProductLineDetails class="OrderLine__details" :data="orderLine" show-quantity />
    </div>
    <div v-if="!disabled" class="OrderLine__returnControls">
      <div v-show="quantity > 0" class="OrderLine__returnMethod">
        <div class="OrderLine__returnMethodLabel">Retur i:</div>
        <ReturnContainerSelect
          :selected="line.return_method_id"
          @change="updateLine({ return_method_id: $event })"
        />
      </div>
      <div v-if="orderLine.quantity > 1" class="OrderLine__count">
        <div class="OrderLine__countLabel">Antal:</div>
        <div class="OrderLine__countInput">
          <div class="Input Input--tight">
            <Input
              :type="inputType.NUMBER"
              :min="0"
              :max="orderLine.rma_info.quantity_available"
              :value="quantity"
              :action="value => updateLine({ quantity: Number(value) })"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import Input from '@components/Input';
//import { InputType } from '@types/InputType';
import ReturnContainerSelect from '../../../ReturnContainerSelect';
import CheckMark from './components/CheckMark';
import RmaLinks from './components/RmaLinks';
import ProductLineDetails from '@scenes/SelfserviceScene/components/ProductLineDetails';

import Input, { InputSize } from '@components/Input';
import { InputType } from '@types/InputType';

import { StatusLabel, types as labelTypes } from '@scenes/SelfserviceScene/components/StatusLabel';

export default {
  name: 'RmaOrderLine',
  components: {
    CheckMark,
    ReturnContainerSelect,
    RmaLinks,
    ProductLineDetails,
    Input,
    StatusLabel,
  },
  props: {
    orderLine: {
      type: Object,
      required: true,
    },
    invoiceNumber: {
      type: Number,
      required: true,
    },
    addLines: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    disabled() {
      return this.orderLine.rma_info.quantity_available < this.orderLine.quantity;
    },
    maxCount() {
      return this.line.quantity;
    },
    line() {
      const lookup = {
        invoice_number: this.invoiceNumber,
        invoice_line_id: this.orderLine.invoice_line_id || this.orderLine.line_id,
      };
      return (
        this.rmaContainer.getDraftLine(lookup) || {
          ...lookup,
          quantity: 0,
        }
      );
    },
    quantity() {
      return this.line ? this.line.quantity : 0;
    },
    valid() {
      return true;
    },
    rmas() {
      if (this.orderLine && this.orderLine.rma_info && this.orderLine.rma_info.rma_references) {
        return this.orderLine.rma_info.rma_references;
      }
      return [];
    },
    quantityAddedToRma() {
      let count = 0;
      this.rmas.forEach(rma => {
        if (rma.id === this.rmaContainer.rma.id) {
          count += rma.quantity;
        }
      });
      return count;
    },
    quantityAddedToRmas() {
      let count = 0;
      this.rmas.forEach(rma => {
        //if (rma.id === this.rmaContainer.rma.id) {
        count += rma.quantity;
        //}
      });
      return count;
    },
  },
  watch: {
    valid(to, from) {
      if (to !== from) {
        this.validator.updateItem(this._uid, to);
      }
    },
  },
  created() {
    this.inputType = InputType;
    this.labelTypes = labelTypes;

    this.validator.addItem(this._uid, this.valid, this.showError);
  },
  mounted() {
    if (this.addFoundLines && this.quantity === 0) {
      this.toogleLine();
    }
  },
  beforeDestroy() {
    this.validator.removeItem(this._uid);

    if (this.quantity > 0 && !this.rmaContainer.saveDrafts) {
      this.rmaContainer.removeDraftLine(this.line);
    }
  },
  methods: {
    showError() {
      console.log('SHOW VALIDATION ERROR');
    },
    updateLine(upsertData) {
      this.rmaContainer.upsertDraftLine({ ...this.line, ...upsertData });
    },
    toogleLine() {
      if (this.disabled) {
        return false;
      }
      if (this.quantity > 0) {
        this.rmaContainer.upsertDraftLine({ ...this.line, ...{ quantity: 0 } });
      } else {
        this.rmaContainer.upsertDraftLine({
          ...this.line,
          ...{ quantity: this.orderLine.quantity },
        });
      }
    },
    remove() {
      this.rmaContainer.removeDraftLine(this.line);
    },
  },
  inject: ['rmaContainer', 'validator', 'addFoundLines'],
};
</script>

<style lang="scss" scoped>
.OrderLine {
  display: flex;

  justify-content: space-between;

  .OrderLine__info {
    flex: 2;
    cursor: pointer;
  }

  &.OrderLine--disabled .OrderLine__check,
  &.OrderLine--disabled .OrderLine__details {
    opacity: 0.5;
    cursor: default;
  }

  .OrderLine__check {
    margin-top: 10px;
    margin-right: 20px;
  }
  .OrderLine__label {
    font-size: 13px;
    margin-bottom: 0.3em;
  }
  .OrderLine__returnMethod,
  .OrderLine__count {
    margin-left: 20px;
    display: flex;
    align-items: center;

    .OrderLine__countInput {
      max-width: 58px;
    }
    .OrderLine__countLabel,
    .OrderLine__returnMethodLabel {
      margin-right: 0.6em;
    }
  }
  .OrderLine__returnControls {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;
  }
}
</style>

<i18n>
  {
    "da": {
      "rmaReturns" : {
        "addedToCurrentReturn": "{n} stk. er tilføjet til denne returnering",
        "addedToOtherReturns": " | {n} stk. er tilføjet til én returnering | {n} stk. er tilføjet til returneringerne"
      }
    }
  }
</i18n>
