<template>
  <div
    :tabIndex="0"
    :class="[
      'Input',
      size === InputSize.SMALL && 'Input--small',
      inputValidated === false && initiated && 'Input--not-validated',
    ]"
  >
    <div :class="['InputWrapper', inputValue === 'number' && 'InputWrapper--number']">
      <input
        v-show="isSelected"
        ref="input"
        :placeholder="placeholder"
        :class="[unit !== '' ? 'has-suffix' : undefined]"
        :type="inputValue"
        :value="value"
        :max="max"
        :min="min"
        @input="
          e => {
            initiateInput();

            // Make sure value isn't outside boundaries
            let value = e.target.value;

            if (type === InputType.NUMBER) {
              value = parseFloat(value);

              if (max !== '' && value > max) {
                value = max;
              } else if (min !== '' && value < min) {
                value = min;
              }
            }

            // Send action with value
            action(value);
          }
        "
        @blur="
          e => {
            // Toggle selected
            toggleSelected();

            // If blurAction is undefined, run action istead - if it's defined
            if (blurAction === undefined && action !== undefined) {
              action(value);
            } else if (blurAction !== undefined) {
              // If both blurAction and action are undefined, then we shouldn't run this
              // otherwise, default to bluraction
              blurAction(value);
            }
          }
        "
      />

      <div
        v-show="!isSelected"
        :style="'width: ' + inputWidth + 'px'"
        :class="['Input__fakeInput', unit !== '' && 'Input__FakeInput--hasSuffix']"
        @mousedown="toggleSelected"
      >
        <span v-if="value === ''" v-html="placeholder || '&nbsp;'"></span>
        <!-- Ouput the different types of value -->
        {{ helpers.manipulation.getInputFormatByType(value, type, this) }}
      </div>

      <!-- Print unit if present -->
      <div v-if="unit" class="InputWrapper__unit">{{ unit }}</div>
    </div>
    <div
      v-if="!inputValidated && initiated"
      :class="['ErrorWrapper', tight && 'ErrorWrapper--tight']"
    >
      {{ validationErrorMessage }}
    </div>
  </div>
</template>

<script>
export const InputSize = {
  REGULAR: 'REGULAR',
  SMALL: 'SMALL',
};

import settings from '@settings';
import helpers from '@helpers';

import Vue from 'vue';
import { InputType } from '@types/InputType';

export default {
  name: 'Input',

  props: {
    placeholder: {
      required: false,
      default: '',
    },
    value: {
      required: true,
      default: '',
    },
    unit: {
      required: false,
      default: '',
    },
    type: {
      required: false,
      default: InputType.TEXT,
    },
    size: {
      required: false,
      default: InputSize.REGULAR,
    },
    placeholder: {
      required: false,
      default: '',
      type: String,
    },
    action: {
      required: false,
      default: value => {
        if (settings.testmode) {
          console.log(
            'Remember to apply a method on the "action" prop for handling value change. New value: ',
            value,
            '!',
          );
        }
      },
      type: Function,
    },
    blurAction: {
      required: false,
      default: undefined,
      type: Function,
    },
    max: {
      required: false,
      default: '',
    },
    min: {
      required: false,
      default: '',
    },
    validationAction: {
      required: false,
      // Definition:
      // ---------------------
      // default: state => {
      //   console.log(state);
      // },
    },
    required: {
      required: false,
      default: false,
    },
    pattern: {
      required: false,
      default: '',
      type: String,
    },
    patternErrorMessage: {
      required: false,
      default: '',
      type: String,
    },

    tight: {
      required: false,
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      isSelected: true, // Set to true, to initially measure the width of the input field
      InputType,
      InputSize,
      inputValidated: false,
      initiated: false,
      inputWidth: 0,
    };
  },

  computed: {
    inputValue() {
      switch (this.type) {
        case InputType.NUMBER:
        case InputType.CURRENCY:
          return 'number';
          break;
        case InputType.EMAIL:
          return 'email';
          break;
        default:
          return 'text';
          break;
      }
    },
    validationErrorMessage() {
      const { value, required, type, patternErrorMessage } = this;

      if (value === '' && required) {
        return 'Du skal indtaste en en værdi i feltet...';
      }

      if (patternErrorMessage !== '') {
        return patternErrorMessage;
      }

      switch (type) {
        case InputType.NUMBER:
        case InputType.CURRENCY:
          return 'Værdien skal være et tal...';
          break;
        case InputType.EMAIL:
          return 'Værdien er ikke en gyldig email...';

          break;
      }
    },
  },

  watch: {
    value(newValue) {
      // Validate on changed value
      this.validationActionWrapper();

      // Set input width if the input is selected
      if (this.isSelected) {
        this.inputWidth = this.$refs.input.getBoundingClientRect().width;
      }
    },
  },

  created() {
    this.helpers = helpers;

    Vue.nextTick(() => {
      const input = this.$refs.input;
      if (input !== undefined) {
        this.inputWidth = input.getBoundingClientRect().width;

        this.isSelected = false;
      }
    });
  },

  mounted() {
    // Initially validate input
    this.validationActionWrapper();
  },

  methods: {
    initiateInput() {
      if (this.initiated === false) {
        this.initiated = true;
      }
    },

    validationActionWrapper() {
      // Set data prop validations status
      this.inputValidated = this.validateInput();

      // Send the state back, if an action is provided
      if (this.validationAction !== undefined) {
        this.validationAction(this.inputValidated);
      }
    },

    validateInput() {
      // Super nifty validation here
      const { value, required, type, pattern } = this;

      if (value === '' && required) {
        return false;
      }

      if (pattern !== '') {
        if (pattern.test(value) === false) {
          return false;
        }
      } else {
        switch (type) {
          case InputType.NUMBER:
          case InputType.CURRENCY:
            if (!(!isNaN(parseFloat(value)) && isFinite(value))) {
              return false;
            }

            break;
          case InputType.EMAIL:
            const regEx = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

            if (regEx.test(value) === false) {
              return false;
            }

            break;
        }
      }

      return true;
    },

    toggleSelected() {
      setTimeout(
        () => {
          // Set isSelected
          this.isSelected = !this.isSelected;

          if (this.isSelected === true) {
            // Set focus to the input field and select the content
            // after the next paint
            this.$nextTick(() => {
              this.$refs.input.focus();
              this.$refs.input.select();
            });
          }
        },

        // Make sure we're all done with the manual set timers before we return to the div element (prevents flikkering)
        this.isSelected ? 350 : 0,
      );
    },
  },
};
</script>

<style lang="scss">
@mixin printStyles() {
  .Input {
    &__fakeInput {
      @media print {
        border-color: transparent;
      }
    }
  }
}

@media print {
  @include printStyles();
}

.ForcedPrint {
  @include printStyles();
}
</style>

<style lang="scss" scoped>
input {
  color: $color-black !important;

  @media print {
    border-color: transparent;
  }
}

.InputWrapper {
  position: relative;
}

.InputWrapper__unit {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0 5px 5px 0;
  padding-right: 10px;
  user-select: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.has-suffix,
.Input__FakeInput--hasSuffix {
  padding-right: 50px;
}

.Input {
  margin-bottom: 0;

  &--small {
    font-size: 12px;
  }

  &--not-validated {
    color: red;

    .Input__fakeInput,
    input {
      border-color: red;
    }
  }
}

.InputWrapper--number,
.InputWrapper--number input {
  text-align: center;
}

.ErrorWrapper {
  padding-left: 0.3em;
  padding-top: 0.4em;
  color: $color-input-error;
  font-size: 12px;

  &--tight {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    color: $color-input-error;
  }
}

@mixin printStyles() {
  input {
    border-color: transparent;
  }
  .Input {
    &__fakeInput {
      border-color: transparent;
    }
  }
}

@media print {
  @include printStyles();
}

.ForcedPrint {
  @include printStyles();
}
</style>
