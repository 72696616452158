<template>
  <div class="ProductLineDetails">
    <div v-if="!hideImage" class="ProductLineDetails__image">
      <ImageComponent :src="data.product.image" :alt="data.product.title" :showFallback="false" />
    </div>
    <div class="ProductLineDetails__content">
      <p class="ProductLineDetails__title">
        {{ title }}
      </p>
      <div v-if="data.is_verified === false">
        <i class="fas fa-exclamation-triangle" style="color: #dc9d12;"></i> Varen er ukendt.
      </div>
      <div class="ProductLineDetails__info">
        <div v-if="data.product.item_number">
          {{ $t('rma.product.item_number') }}: {{ data.product.item_number }}
        </div>
        <div v-if="data.product.item_reference">
          {{ $t('rma.product.item_reference') }}: {{ data.product.item_reference }}
        </div>
        <div v-if="showQuantity">Antal: {{ data.quantity }} stk.</div>
        <template v-if="data.vehicle">
          <div>
            <span v-if="data.vehicle.license_plate"
              >{{ $t('rma.product.vehicle.license_plate') }}: {{ data.vehicle.license_plate }}
              <template v-if="data.vehicle.id && settings.organization === 'VAU'"
                >,
              </template></span
            >
            <span v-if="data.vehicle.id && settings.organization === 'VAU'">
              {{ $t('rma.product.vehicle.vehicle.id') }}: {{ data.vehicle.id }}
            </span>
          </div>
          <div>
            <span v-if="data.vehicle.description">{{ data.vehicle.description }}</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import settings from '@settings';
import ImageComponent from '@components/ImageComponent';

export default {
  name: 'ProductLineDetails',

  components: {
    ImageComponent,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    showQuantity: {
      type: Boolean,
      default: false,
    },
    hideImage: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    title() {
      if (this.data.product.title === undefined || this.data.product.title === '') {
        return this.data.product.item_reference ?? '';
      }
      return this.data.product.title;
    },
  },

  created() {
    this.settings = settings;
  },
};
</script>

<style lang="scss" scoped>
.ProductLineDetails {
  display: flex;
  white-space: normal;

  .ProductLineDetails__image {
    width: 70px;
    max-width: 70px;
    min-height: 70px;
    margin-right: 20px;
  }

  .ProductLineDetails__title {
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
  }

  .ProductLineDetails__info {
    font-size: 12px;
    margin-bottom: 0;
    color: $color-prim-grey-dark;
    line-height: 18px;
  }

  .ProductLineDetails__quantity {
    font-weight: normal;
    color: $color-prim-grey-dark;
  }
}
</style>
