<template>
  <div class="RmaLinePicker">
    <RmaOrderLookup v-if="lookup" :lookup="lookup" :hide-header="true"></RmaOrderLookup>
    <div v-else>
      <div class="RmaLinePicker__header">
        <SearchField
          v-if="searchEnabled"
          class="RmaLinePicker__search"
          :placeholder="searchPlaceholder"
          :value.sync="searchQuery"
          :typeahead="false"
          :auto-focus="true"
          :options="[
            {
              isRefined: selectedOption === 'query',
              label: 'Alle',
              value: 'query',
            },
            {
              isRefined: selectedOption === 'orderNumber',
              label: 'Ordrenummer',
              value: 'orderNumber',
              placeholder: 'Søg i ordrenummer',
            },
            {
              isRefined: selectedOption === 'invoiceNumber',
              label: 'Fakturanummer',
              value: 'invoiceNumber',
              placeholder: 'Søg i fakturanummer',
            },
          ]"
          :selected-option.sync="selectedOption"
          @search="queueQuery"
        />
        <ReturnContainerSelect
          class="RmaLinePicker__returnMethodId"
          placeholder="Vælg kasse"
          :selected="rmaContainer.getLatestReturnMethodId"
          @change="updateReturnMethodId"
        />
      </div>

      <ul class="RmaLinePicker__queries">
        <li v-for="(query, index) in queries" :key="query.query" class="RmaLinePicker__query">
          <RmaOrderLookup
            :execute="autoLookup"
            :lookup="query"
            :hide-header="false"
            :collapsed="multiplyQueries && !query.focus"
            @toogle="queryToogle(query.query)"
            @remove="removeQuery(query.query)"
          ></RmaOrderLookup>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SearchField from '@components/SearchField';
import SpecialDeliveryContainer from '@scenes/SelfserviceScene/containers/SpecialDeliveryContainer';
import ReturnContainerSelect from './components/ReturnContainerSelect';

import RmaOrderLookup from './components/RmaOrderLookup';
import Select from '@components/Select';

export default {
  name: 'RmaLinePicker',
  components: {
    SearchField,
    RmaOrderLookup,
    ReturnContainerSelect,
  },
  props: {
    lookup: {
      type: Object,
      default: null,
    },
    addFoundLines: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchQuery: '',
      queries: [],
      autoLookup: true,
      selectedOption: 'query',
    };
  },
  computed: {
    searchEnabled() {
      return this.lookup == undefined;
    },
    searchPlaceholder() {
      return this.queries.length > 0 ? 'Søg flere' : 'Søg varenummer, fakturanummer...';
    },
    multiplyQueries() {
      return this.queries.length > 1;
    },
    itemsCount() {
      return this.queries.reduce((total, query) => {
        return total + query.lines.reduce((lineTotal, line) => lineTotal + line.quantity, 0);
      }, 0);
    },
  },
  mounted() {},
  methods: {
    removeQuery(query) {
      const index = this.queries.findIndex(q => q.query === query);

      if (index !== -1) {
        this.queries.splice(index, 1);
      }
    },
    queueQuery() {
      if (
        this.searchQuery !== '' &&
        this.queries.findIndex(query => query.query === this.searchQuery) === -1
      ) {
        const queryObj = {
          query: this.searchQuery,
          type: this.selectedOption,
          fulfilled: false,
          focus: false,
          lines: [],
        };

        this.queries.unshift(queryObj);
      }
      this.queryToogle(this.searchQuery);
      this.searchQuery = '';
    },
    queryToogle(query) {
      this.queries.forEach((item, index) => {
        if (item.query === query) {
          item.focus = !item.focus;
          // TODO: if in focus - scroll to query
        } else if (item.focus) {
          item.focus = false;
        }
      });
    },
    updateReturnMethodId(id) {
      this.rmaContainer.setReturnMethodId(id);
    },
  },
  provide() {
    return {
      addFoundLines: this.addFoundLines,
    };
  },
  inject: ['rmaContainer'],
};
</script>

<style lang="scss" scoped>
.RmaLinePicker {
  .RmaLinePicker__queries {
    min-height: 100px;
  }
  .RmaLinePicker__search {
    flex: 1;
  }
  .RmaLinePicker__query {
    margin-bottom: 10px;
  }
  .RmaLinePicker__header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  .RmaLinePicker__returnMethodId {
    display: flex;
    margin-left: 20px;
  }
}
</style>
