var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"RmaLinePicker"},[(_vm.lookup)?_c('RmaOrderLookup',{attrs:{"lookup":_vm.lookup,"hide-header":true}}):_c('div',[_c('div',{staticClass:"RmaLinePicker__header"},[(_vm.searchEnabled)?_c('SearchField',{staticClass:"RmaLinePicker__search",attrs:{"placeholder":_vm.searchPlaceholder,"value":_vm.searchQuery,"typeahead":false,"auto-focus":true,"options":[
          {
            isRefined: _vm.selectedOption === 'query',
            label: 'Alle',
            value: 'query',
          },
          {
            isRefined: _vm.selectedOption === 'orderNumber',
            label: 'Ordrenummer',
            value: 'orderNumber',
            placeholder: 'Søg i ordrenummer',
          },
          {
            isRefined: _vm.selectedOption === 'invoiceNumber',
            label: 'Fakturanummer',
            value: 'invoiceNumber',
            placeholder: 'Søg i fakturanummer',
          } ],"selected-option":_vm.selectedOption},on:{"update:value":function($event){_vm.searchQuery=$event},"update:selectedOption":function($event){_vm.selectedOption=$event},"update:selected-option":function($event){_vm.selectedOption=$event},"search":_vm.queueQuery}}):_vm._e(),_c('ReturnContainerSelect',{staticClass:"RmaLinePicker__returnMethodId",attrs:{"placeholder":"Vælg kasse","selected":_vm.rmaContainer.getLatestReturnMethodId},on:{"change":_vm.updateReturnMethodId}})],1),_c('ul',{staticClass:"RmaLinePicker__queries"},_vm._l((_vm.queries),function(query,index){return _c('li',{key:query.query,staticClass:"RmaLinePicker__query"},[_c('RmaOrderLookup',{attrs:{"execute":_vm.autoLookup,"lookup":query,"hide-header":false,"collapsed":_vm.multiplyQueries && !query.focus},on:{"toogle":function($event){return _vm.queryToogle(query.query)},"remove":function($event){return _vm.removeQuery(query.query)}}})],1)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }