<template>
  <div class="Header" @click="$emit('toogle')">
    <div class="Header__query">"{{ query }}"</div>
    <div class="Header__status">
      <div v-if="progress" class="Header__progress">
        <Spinner :width="7" />
      </div>
      <div
        v-else
        :class="[
          'Header__statusMessage',
          statusType ? `Header__statusMessage--${statusType}` : undefined,
        ]"
      >
        {{ status }}
      </div>
    </div>
    <Icon class="Header__control" @click="$emit('remove')">
      <i class="far fa-trash-alt"></i>
    </Icon>
  </div>
</template>

<script>
import Spinner from '@components/Spinner';
import { Icon } from '@components/Buttons';

export default {
  name: 'Header',
  components: {
    Spinner,
    Icon,
  },
  props: {
    query: {
      type: String,
      required: true,
    },
    progress: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: '',
    },
    statusType: {
      type: String,
      default: '',
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.Header {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  align-items: center;
  min-height: 45px;
  background: $color-prim-grey-light;
  //border-bottom: 1px solid $color-prim-grey;
  cursor: pointer;
  .Header__query {
    font-size: 16px;
    flex: 1;
  }
  .Header__progress {
    width: 30px;
    height: 30px;
  }
  .Header__status {
    display: flex;
    align-items: center;
  }
  .Header__control {
    font-size: 15px;
    margin-left: 1em;
    padding-left: 0.6em;
    border-left: 1px solid #d2d2d2;
  }

  .Header__statusMessage {
    padding: 0.2em 0.8em;
    font-size: 14px;
    color: $color-white;
    border-radius: 2px;

    color: $color-prim-black-light;
    white-space: nowrap;

    &--success {
      color: $color-white;
      background-color: $color-extra-success;
    }

    &--error {
      color: $color-white;
      background-color: $color-extra-error;
    }

    &--warning {
      color: $color-white;
      background-color: $color-extra-warning;
    }
  }
}
</style>
